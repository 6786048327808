import React from 'react'
import { css } from '@emotion/react'
import useTheme from '@mui/material/styles/useTheme'
import LazyLoad from 'react-lazyload'
import { graphql, navigate } from 'gatsby'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/lab/Pagination'
import Page from 'components/Page'
import Link from 'components/common/Link'
import { getDateAgo } from 'utils'

export interface PostTemplateProps {
  data: {
    allMarkdownRemark: {
      totalCount: number
      edges: Array<{
        node: {
          html: string
          excerpt: string
          wordCount: {
            words: number
          }
          fields: {
            slug: string
            date: string
          }
          frontmatter: {
            title: string
            date: string
            author: string
            thumbnail: string
            description: string
            lastUpdated: string
            tags: string[]
            category: string
          }
        }
      }>
    }
  }
  pageContext: {
    previousPagePath: number
    nextPagePath: number
    pageNumber: number
    numberOfPages: number
    humanPageNumber: number
    limit: number
    skip: number
  }
}

const PostTemplate = ({ data, pageContext }: PostTemplateProps) => {
  const theme = useTheme()
  const { humanPageNumber, numberOfPages } = pageContext

  function onPageChange(e: React.ChangeEvent<any>, selectedPage: any) {
    const nextPg = selectedPage === 1 ? '' : selectedPage
    navigate(`/posts/${nextPg}`)
  }

  const pagination = (
    <Pagination
      boundaryCount={2}
      page={humanPageNumber}
      count={numberOfPages}
      hideNextButton={humanPageNumber === numberOfPages}
      hidePrevButton={humanPageNumber === 1}
      onChange={onPageChange}
      showFirstButton
      showLastButton
      siblingCount={1}
    />
  )

  function renderPost(post: { excerpt: any; fields: any; frontmatter: any }) {
    return (
      <Typography style={{ marginBottom: 30 }} component="div">
        <Link to={post.fields.slug}>
          <Typography variant="h5" gutterBottom>
            {post.frontmatter.title}
          </Typography>
        </Link>
        <Typography variant="subtitle1">{post.excerpt}</Typography>
        <Typography variant="body2">
          {getDateAgo(post.frontmatter.date)}
        </Typography>
      </Typography>
    )
  }

  return (
    <Page
      title="Posts"
      subtitle={`Viewing 10 / ${data.allMarkdownRemark.totalCount} posts`}
    >
      <div
        css={css`
          margin: auto;
        `}
      >
        <div
          css={css`
            margin-bottom: 30px;
            ${theme.breakpoints.down('xs')} {
              text-align: center;
            } ;
          `}
        >
          {pagination}
        </div>
        {data.allMarkdownRemark.edges.map(({ node: post }) => (
          <LazyLoad key={post.fields.slug} height={100} once>
            {renderPost(post)}
          </LazyLoad>
        ))}
      </div>
    </Page>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
      filter: { frontmatter: { pageType: { eq: "post" } } }
    ) {
      totalCount
      edges {
        node {
          html
          excerpt(pruneLength: 300)
          wordCount {
            words
          }
          fields {
            slug
            date(formatString: "MMMM Do, YYYY")
          }
          frontmatter {
            title
            date
            thumbnail
            lastUpdated
            description
            author
            tags
            category
          }
        }
      }
    }
  }
`

export default PostTemplate
